import React, { useState, useRef } from 'react'
import ReactWOW from 'react-wow';

function StaffGrid() {

    const staff = [
        {
            title: "Brian",
            desc: "President",
            img: '/about/staff/brian.png',
            talent: 'I can fire bottlecaps with a finger-snap.',
            alt: 'Photo of Brian',
        },
        {
            title: "Doug",
            desc: "Chief Operating Officer",
            img: '/about/staff/doug3.png',
            talent: 'I can speak low-German. Wo geiht?',
            alt: 'Photo of Doug',
        },

        {
            title: "Sobia",
            desc: "Senior software developer",
            img: '/about/staff/sobia.png',
            talent: 'Coming Soon',
            alt: 'Photo of Sobia'
        },
        {
            title: "David",
            desc: "Multimedia Development Lead",
            img: '/about/staff/david.png',
            talent: 'I draw all the Workhub sketches.',
            alt: 'Photo of David'
        },
        {
            title: "Pamela",
            desc: "Fulfillment",
            img: '/about/staff/pam.png',
            talent: 'Plating copper & making jewellery',
            alt: 'Photo of Pamela'
        },

        {
            title: "Sahar",
            desc: "Accounting Lead",
            img: '/about/staff/sahar.png',
            talent: 'I can overcome any challenge that is thrown my way.',
            alt: 'Photo of Sahar'
        },
        {
            title: "Mike",
            desc: "Product Manager",
            img: '/about/staff/mike.png',
            talent: 'I sparkle majestically in the sun.',
            alt: 'Photo of Mike'
        },
        {
            title: "Danielle",
            desc: "Content Developer",
            img: '/about/staff/danielle3.png',
            talent: "I'm an awesome baker. Cakes, cookies, pies... if it's sweet I am your girl!",
            alt: 'Photo of Danielle'
        },
        {
            title: "Alyssa",
            desc: "Fulfillment Lead",
            img: '/about/staff/alyssa2.png',
            talent: "Goat wrangler",
            alt: 'Photo of Satnam'
        },

        {
            title: "Ray",
            desc: "Senior software developer",
            img: '/about/staff/ray.png',
            talent: 'Coming Soon',
            alt: 'Photo of Ray'
        },
        {
            title: "Vicky",
            desc: "Multimedia Developer",
            img: '/about/staff/vicki.png',
            talent: 'I can play Dance Dance Revolution on Expert mode!',
            alt: 'Photo of Vicky'
        },
        {
            title: "Luke",
            desc: "Junior software developer",
            img: '/about/staff/luke.png',
            talent: 'Coming Soon',
            alt: 'Photo of Luke'
        },
        {
            title: "Natalie",
            desc: "Chef + Fulfillment",
            img: '/about/staff/natalie.png',
            talent: 'I can cause 22 damage with a single attack in a Gloomhaven boss fight!',
            alt: 'Photo of Natalie'
        },
        // {
        //     title: "Mac",
        //     desc: "Product Management Lead",
        //     img: '/about/staff/mac.png',
        //     talent: 'I can slam dunk 98% of the time. Ooh yeaaah.',
        //     alt: 'Photo of Mac'
        // },
        {
            title: "Keith",
            desc: "IT / Infrastructure",
            img: '/about/staff/keith.png',
            talent: "I guess I'll go with juggling. I suppose it's secret because I rarely do it when people are around.",
            alt: 'Photo of Keith'
        },
        {
            title: "Paige",
            desc: "Accounting",
            img: '/about/staff/paige3.png',
            talent: "Basketball",
            alt: 'Photo of Paige'
        },
        {
            title: "Kristine",
            desc: "Quality Assurance Lead",
            img: '/about/staff/kristine2.png',
            talent: "I exist almost solely on rice. RICE IS LIFE <3",
            alt: 'Photo of Kristine'
        },
        {
            title: "Hunter",
            desc: "software developer",
            img: '/about/staff/hunter4.png',
            talent: "I brew my own beer and sometimes it's even drinkable",
            alt: 'Photo of Hunter'
        },
        {
            title: "Russell",
            desc: "UX/UI Design Lead",
            img: '/about/staff/russell.png',
            talent: "Down a tall glass of milk faster than the average Joe.",
            alt: 'Photo of Russell'
        },
        {
            title: "Bin",
            desc: "software developer",
            img: '/about/staff/bin.png',
            talent: "Badminton",
            alt: 'Photo of Bin'
        },
        {
            title: "Ed",
            desc: "Quality Assurance",
            img: '/about/staff/ed.png',
            talent: "Poodle Whisperer",
            alt: 'Photo of Ed'
        },
        {
            title: "Kendra",
            desc: "Customer Success Lead",
            img: '/about/staff/kendra.png',
            talent: "I give the best hugs <3",
            alt: 'Photo of Kendra'
        },
        {
            title: "Leo",
            desc: "Sales Lead",
            img: '/about/staff/leo.png',
            talent: "Guitar",
            alt: 'Photo of Leo'
        },
        {
            title: "Aidan",
            desc: "software developer",
            img: '/about/staff/aidan.png',
            talent: "Americans think I'm Canadian, Canadians think I'm American. There's no winning.",
            alt: 'Photo of Aidan'
        },
        {
            title: "Miles",
            desc: "IT",
            img: '/about/staff/miles.png',
            talent: "I can play the recorder beautifully",
            alt: 'Photo of Miles'
        },
        {
            title: "Cecilia",
            desc: "UX/UI Designer",
            img: '/about/staff/cecilia.png',
            talent: "Coming Soon",
            alt: 'Photo of Cecilia'
        },
        {
            title: "Rob",
            desc: "Junior software developer",
            img: '/about/staff/rob.png',
            talent: "Dog Whisperer",
            alt: 'Photo of Rob'
        },

        {
            title: "Shiana",
            desc: "Junior software developer",
            img: '/about/staff/shiana.png',
            talent: "Bollywood Dance",
            alt: 'Photo of Shiana'
        },
        {
            title: "Bibek",
            desc: "Junior software developer",
            img: '/about/staff/bibek.png',
            talent: "Dad jokes",
            alt: 'Photo of Bibek' 
        },
        {
            title: "Kateryna",
            desc: "Sales Administrator",
            img: '/about/staff/Kateryna.png',
            talent: "Coming Soon",
            alt: 'Photo of Kateryna'
        },
        {
            title: "Hardish",
            desc: "Junior software developer",
            img: '/about/staff/hardish.png',
            talent: "Coming Soon",
            alt: 'Photo of Hardish'
        },
        {
            title: "Feras",
            desc: "Junior software developer",
            img: '/about/staff/feras.png',
            talent: "I can eat two boxes of cereal",
            alt: 'Photo of Feras'
        },
        {
            title: "Guillaume",
            desc: "Junior software developer",
            img: '/about/staff/guillaume.png',
            talent: "Coming soon",
            alt: 'Photo of Guillaume'
        },
        {
            title: "Marvic",
            desc: "Junior software developer",
            img: '/about/staff/marvic.png',
            talent: "Coming soon",
            alt: 'Photo of Marvic'
        },
        {
            title: "Ramya",
            desc: "Quality Assurance",
            img: '/about/staff/Ramya.png',
            talent: "coming soon",
            alt: "Photo of Ramya"
        },
        {
            title: "VP",
            desc: "IT / Infrastructure",
            img: '/about/staff/VP.png',
            talent: "coming soon",
            alt: "Photo of VP"
        },
        {
            title: "Brett",
            desc: "Software Developer",
            img: '/about/staff/Brett.png',
            talent: "I can wire anything directly to anything!",
            alt: "Photo of Brett",
            fit: 'contain'
        },
        {
            title: "Lucas",
            desc: "Sales Account Executive",
            img: '/about/staff/Lucas.png',
            talent: "coming soon",
            alt: "Photo of Lucas"
        },
        {
            title: "Carol",
            desc: "Accounting",
            img: '/about/staff/Carol.png',
            talent: "coming soon",
            alt: "Photo of Carol",
            fit: 'contain'
        },
        {
            title: "Nidhi",
            desc: "Customer Success",
            img: '/about/staff/Nidhi.png',
            talent: "coming soon",
            alt: "Photo of Nidhi"
        },
        {
            title: "Sheri",
            desc: "Customer Success",
            img: '/about/staff/Sheri.png',
            talent: "coming soon",
            alt: "Photo of Sheri"
        },
        {
            title: "Sarabjeet",
            desc: "Customer Success",
            img: '/about/staff/Sarabjeet.png',
            talent: "coming soon",
            alt: "Photo of Sarabjeet"
        },
        {
            title: "Michael",
            desc: "Customer Success",
            img: '/about/staff/Michael.png',
            talent: 'I sing, I dance. For sports, I bike, and play Basketball',
            alt: 'Photo of Michael'
        }
    ]

    const StaffCards = () => {
        const [flip, setFlip] = useState(false);

        return staff.map((data) => (

            <div className="scene scene--card" onClick={
                () => flip === data ? setFlip(false) : setFlip(data)} key={data}>
                <div className={`staff-card ${flip === data ? "is-flipped" : ""}`}>


                    <div className="card__face card__face--front">

                        <div className='staff_img'>
                            <img alt={data.alt} src={data.img} style={{objectFit: data.fit? data.fit: 'cover'}}/>
                        </div>

                        <h4>{data.title}</h4>
                        <p>{data.desc}</p>

                        <div className="flip_card">
                            <p>flip {'>'}</p>
                        </div>

                    </div>

                    <div className="card__face card__face--back">
                        <h2>Secret Talent:</h2>
                        <p>{data.talent}</p>
                    </div>
                </div>
            </div>
        ));
    };

   



    return (

        <div className="container" style={{ marginTop: '60px', marginBottom: '60px' }}>

            <div className="hosting_title security_title text-center" style={{ marginBottom: '30px' }}>
                <ReactWOW animation="fadeInUp" delay="0.2s">
                    <h2><span>Introducing</span> Our Team</h2>
                </ReactWOW>
            </div>

            <section className="staff-grid">

                <StaffCards />

         


            </section>


        </div>
    )
}

export default StaffGrid
